import {
  IconRefresh,
  IconTargetArrow,
  IconUsers,
  IconListCheck,
  IconCashBanknote,
  IconBinaryTree,
  IconFileDollar,
  IconForms,
  IconTableOptions,
  IconUpload,
  IconChartBar,
  IconCertificate,
  IconSettings,
  IconAccessPoint,
  IconHistoryToggle,
  IconBuilding,
  IconHelp,
  IconArrowsExchange2,
  IconPercentage,
  IconSquareKey,
  IconBriefcase,
  IconFolders,
  IconSparkles,
  IconTrash,
  IconPresentationAnalytics,
  IconDotsVertical,
  IconChartPie,
  IconHistory,
  IconToolsKitchen2,
  IconSpy,
  IconRouteSquare,
  IconPlus,
  IconEdit,
  IconFlask,
  IconDownload,
  IconAlertTriangle,
} from "@tabler/icons-react";

import { IconLayoutListOffset } from "./custom/IconLayoutListOffset";

export const AppIcon = {
  GettingStarted: IconListCheck,
  Employees: IconUsers,
  Ranges: IconLayoutListOffset,
  Cycles: IconRefresh,
  Benchmarking: IconTargetArrow,
  TotalRewards: IconCashBanknote,
  Settings: IconSettings,
  AdminTools: IconToolsKitchen2,

  // global features
  Analytics: IconChartBar,
  TableSettings: IconTableOptions,
  AI: IconSparkles,
  Impersonation: IconSpy,

  // cycles secondary features
  AwardLetters: IconCertificate,

  // employees secondary features
  Manage: IconBriefcase,

  // ranges secondary features
  JobArchitecture: IconBinaryTree,
  PayPoints: IconFileDollar,
  CustomFields: IconForms,
  AutoBenchmarking: IconRouteSquare,

  // tr secondary features
  EmployeePreview: IconPresentationAnalytics,

  // settings seconary features
  Integrations: IconAccessPoint,
  SyncHistory: IconHistoryToggle,
  Company: IconBuilding,
  Glossary: IconHelp,
  ExchangeRates: IconArrowsExchange2,
  EquityGrants: IconPercentage,
  Permissions: IconSquareKey,
  Files: IconFolders,
  Equity: IconChartPie,
  CompHistory: IconHistory,

  // ux / actions
  MoreOptions: IconDotsVertical,
  Delete: IconTrash,
  ToolTip: IconHelp,
  Add: IconPlus,
  Edit: IconEdit,
  Experiment: IconFlask,
  Warning: IconAlertTriangle,

  Download: IconDownload,
  Upload: IconUpload,
};
